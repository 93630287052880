module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx",".md"],"gatsbyRemarkPlugins":[{"options":{"icon":false},"resolve":"gatsby-remark-autolink-headers"},{"options":{"maxWidth":800,"wrapperStyle":"\n                margin-left: -2rem;\n                margin-right: -2rem;\n              "},"resolve":"gatsby-remark-images"},{"options":{"wrapperStyle":"margin-bottom: 1.0725rem"},"resolve":"gatsby-remark-responsive-iframe"},{"resolve":"gatsby-remark-copy-linked-files","options":{}},{"resolve":"gatsby-remark-smartypants","options":{}},{"resolve":"gatsby-remark-prismjs","options":{}}],"defaultLayouts":{},"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/opt/build/repo"},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-134252917-1","head":false,"anonymize":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0},
    },{
      plugin: require('../node_modules/gatsby-theme-grape-blog/gatsby-browser.js'),
      options: {"plugins":[],"googleAnalyticsId":"UA-134252917-1","gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-images","options":{"maxWidth":800,"wrapperStyle":"\n                margin-left: -2rem;\n                margin-right: -2rem;\n              "}},"gatsby-remark-prismjs"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Tomek Kolasa","short_name":"Tomek Kolasa","start_url":"/","background_color":"#ffffff","theme_color":"#7b1fff","display":"minimal-ui","icon":"content/assets/logo.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"91ae4fac577e9c3a06cd0e4248657165"},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/opt/build/repo/src/components/layout.js"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
